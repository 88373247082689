<template>
<footer>

<div class="links">
  <div class="hvr-grow"><a href="https://music.163.com/#/artist?id=33849815" target="_blank">NetEaseMusic</a></div>
  <div class="hvr-grow"><a href="https://on.soundcloud.com/ZNxs2" target="_blank">SoundCloud</a></div>
  <div class="hvr-grow"><a href="https://b23.tv/xYLdApI" target="_blank">Bilibili</a></div>
</div>

<div class="bei-an">
  <img alt="" src="../assets/beianicon.png">
  <a href="https://beian.miit.gov.cn" target="_blank">粤ICP备2022016866号</a>
</div>
</footer>
</template>

<script>
export default {
  name: "TheFooter"
}
</script>

<style scoped lang="less">
footer{
  height: 400px;
  width: 100%;
  position: absolute;
  bottom: -400px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .links{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    >div{
      margin: 12px 0;
    }
  }

  .bei-an{
    height: 24px;
    line-height: 24px;
    position: absolute;
    bottom: 0;
    z-index: 1001;
    margin-bottom: 24px;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    a{
      margin-left: 6px;
      font-size: 0.5rem;
      font-family: 'Roboto', sans-serif;
    }
  }

}

</style>