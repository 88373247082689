<template>
  <div class="del-item-wrap">
    <div>
      <p>Delete Post</p>
    </div>
    <div class="post-list">
      <!--加载-->
      <ul class="loading-wrap" v-show="isLoading">
        <li v-for="index in 10 " :key="index">
          <div class="loading-cover"/>
          <div class="loading-title"/>
          <div class="loading-published"/>
          <div class="loading-type"/>
        </li>
      </ul>
      <!--实例-->
      <ul v-show="!isLoading">
        <li v-for="post in postList" :key="post.id">
          <div v-show="post.coverSrc" class="cover-wrap">
            <img alt="" :src="post.coverSrc">
          </div>
          <div class="title">
            <p :title="post.title">{{ post.title }}</p>
          </div>
          <div class="view">
            <span>阅读：</span><p>{{ post.view }}</p>
          </div>
          <div v-show="post.published" class="published">
            <p>{{ post.published }}</p>
          </div>
          <div class="post-type">
            <p v-show="post.postType===0">文章</p>
            <p v-show="post.postType===1">视频</p>
            <p v-show="post.postType===2">音乐</p>
          </div>
          <div class="btn">
            <button
                @click="delId=post.id;
                delType=post.postType;
                delTitle=post.title;
                delCover=post.coverSrc;
                showConfirm=true"
                type="button">delete
            </button>
          </div>
        </li>
      </ul>
    </div>
    <div class="tool-bar">
      <div class="page-btn-wrap">
        <div class="pre-btn">
          <button :disabled="page===0" @click="pageTurn(0)" type="button">Previous</button>
        </div>
        <div class="next-btn">
          <button :disabled="isEmpty" @click="pageTurn(1)" type="button">Next</button>
        </div>
      </div>
    </div>
    <div v-if="showConfirm" @click.self="hideConfirm" class="confirm-del-wrap">
      <div v-show="delId" class="confirm-del-box">
        <div class="prompt">
          <p>Sure to DELETE?</p>
          <div class="prompt-post-inf">
            <div v-show="delCover" class="prompt-post-cover">
              <img alt="" :src="delCover">
            </div>
            <div class="prompt-post-title">
              <p :title="delTitle">{{ delTitle }}</p>
            </div>
          </div>
        </div>
        <button @click="hideConfirm" type="button">Cancel</button>
        <button @click="delPost(delId,delType)" type="button">Delete</button>
      </div>
    </div>
  </div>
</template>

<script>
import request from "@/api";

export default {
  name: "delPost",
  data() {
    return {
      page: 0,
      isEmpty: false,
      showConfirm: false,
      isLoading: true,
      delId: 0,
      delType: 0,
      delTitle: '',
      delCover: '',
      postList: [
        {
          id: '',
          title: "",
          postType: 0,
          coverSrc: '',
          published: '',
          view:0
        },
      ]
    }
  },
  async created() {
    this.postList = await request.getMiniPost(this.page)
    this.isLoading = false
  },
  methods: {
    async delPost(id, postType) {
      let formData = new FormData()
      formData.append("id", id)
      formData.append("postType", postType)
      formData.append("page", this.page)
      let rs = await request.delPost(formData)
      if (rs.length >= 1) {
        this.postList = rs
      } else {
        alert("操作失败！")
      }
      this.hideConfirm()
    },
    hideConfirm() {
      this.showConfirm = !this.showConfirm
      this.delId = ''
      this.delType = 0
      this.delTitle = ''
      this.delCover = ''
    },
    async pageTurn(type) {
      if (type === 0) {
        this.page = this.page - 1
        if (this.page < 0)
          this.page = 0
      } else {
        this.page = this.page + 1
      }
      this.isLoading = true;
      let rs = await request.getMiniPost(this.page)

      this.postList = rs

      this.isEmpty = rs.length < 10;
      this.isLoading = false;
    }
  }
}
</script>

<style lang="less" scoped>
.cover-wrap {
  height: 48px;

  img {
    width: 48px;
    height: 48px;
    object-fit: cover;
  }
}

.post-list {
  margin-left: 16px;
  margin-bottom: 16px;

  ul {
    li {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;

      margin-top: 16px;
      height: 48px;

      > div {
        margin-right: 16px;
        padding-right: 16px;
        border-right: 2px #f1f1f1 solid;
      }

      > div:last-child {
        border-right: none;
      }

      .btn {
        button {
          border: none;
        }
      }
    }

  }

  .loading-wrap {
    li {
      > div {
        background-color: #f1f1f1;
        margin-right: 32px;
      }

      div:first-child {
        width: 48px;
        height: 48px;
        padding: 0;
      }

      div:nth-child(2) {
        width: 134px;
        height: 48px;
      }

      div:nth-child(3) {
        width: 142px;
        height: 48px;
      }

      div:nth-child(4) {
        width: 20px;
        height: 24px;
      }
    }
  }
}

.post-type {
  p {
    line-height: 24px;
    padding: 0 2px;
    background-color: black;
    color: white;
  }
}

.page-btn-wrap {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  width: 400px;

  div {
    button {
      border: none;
    }
  }
}

.title {
  width: 150px;

  p {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
.view{
  span{
    color: gray;
  }
  p{
    display: inline;
  }
}

.confirm-del-wrap {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  backdrop-filter: blur(20px);

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  .confirm-del-box {
    width: 300px;
    height: 200px;
    background-color: gray;

    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;

    .prompt {
      display: inline;
      width: 300px;
      color: white;
      text-align: center;

      .prompt-post-inf {
        > div {
          margin-top: 16px;
        }

        .prompt-post-cover {
          img {
            width: 48px;
            height: 48px;
            object-fit: cover;
          }
        }

        .prompt-post-title {
          padding: 0 16px;

          p {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }
    }
  }
}

</style>