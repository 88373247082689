<template>
  <Transition name="fade">
    <header v-show="showNav">
      <div class="nav-wrap">
        <div class="blog-title" v-html="blogTitle"/>
      </div>
    </header>
  </Transition>
</template>

<script>
export default {
  name: "TheHeader",
  data() {
    return {
      blogTitle: `<span style="font-weight: lighter;color: var(--theme-white);">/ / / </span>
                    <span style="font-weight: lighter;color: var(--theme-white);">C a l l m e </span>
                        <span style='color: var(--theme-red);font-weight: bolder;'>R E D </span>
                            <span style="color: var(--theme-red);"> / / /</span>`,
      showNav: true
    }
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll)
  },
  methods: {
    handleScroll() {
      let scrollY = document.documentElement.scrollTop
      if (scrollY > this.lastScrollY) {
        this.showNav = false
      } else if (scrollY < this.lastScrollY) {
        this.showNav = true
      } else if (scrollY === 0) {
        this.showNav = true
      } else {
        this.showNav = true
      }
      this.lastScrollY = scrollY
    }
  }
}
</script>

<style lang="less" scoped>
.fade-enter-active,
.fade-leave-active {
  transition: all 0.5s ease;
}
.fade-enter-from,
.fade-leave-to {
  transform: translateY(-120px);
}

header {
  position: fixed;
  width: 100%;
  z-index: 1000;

  backdrop-filter: blur(10px);
  background-color: rgba(0, 0, 0, 0.1);
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.25);

  .nav-wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;

    .nav-menu {
      height: 52px;
      line-height: 52px;
      width: 480px;
      float: right;
      display: flex;
      justify-content: space-around;
      align-items: center;
      flex-direction: row;

      .nav-menu-item {
        a {
          -webkit-user-select: none;
          -moz-user-select: none;
          -ms-user-select: none;
          user-select: none;
          color: var(--font-color);
        }
      }
    }
  }
}

.blog-title {
  height: 72px;
  line-height: 72px;
  cursor: pointer;

  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
</style>