import {createStore} from 'vuex'

export default createStore({
    state: {
        screenType: 1, // 1 : 横屏； 2 ： 竖屏； 3 ： 平板；
        screenWidth: 1080,
        isLogin: null,
        isPageLoading: false
    },

    getters: {
        getIsLogin: state => state.isPageLoading,
        getIsPageLoading: state => state.isPageLoading
    },

    mutations: {
        setScreenType(state, type) {
            state.screenType = type
        },
        setScreenWidth(state, width) {
            state.screenWidth = width
        },
        setIsLogin(state, isLogin) {
            state.isPageLoading = isLogin
        },
        setIsLoading(state, isLoading) {
            state.isPageLoading = isLoading
        }
    },

    actions: {},
    modules: {}
})
