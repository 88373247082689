<template>
  <div class="video-add-wrap" :style="{'background-color':video.themeColor}">
    <div class="video-add-box">
      <div class="cover-add-wrap">
        <p>选择封面</p>
        <input ref="cover-input-er" @change="coverHandle" type="file">
        <color-selector @getColor="setThemeColor" v-show="video.cover" :imgSrc="coverPath"/>
      </div>
      <div class="video-input-wrap">
        <input type="file" ref="videoInput" @change="videoHandle()">
      </div>
      <div class="text-add-wrap">
        <div class="text-add-box">
          <div class="title-box">
            <p>标题</p>
            <input v-model="video.title">
          </div>
          <div class="summary-box">
            <p>简介</p>
            <input v-model="video.summary">
          </div>
          <div class="content-box">
            <p>内容</p>
            <input v-model="video.content">
          </div>
        </div>
      </div>
      <div class="submit">
        <button @click="submit" ref="submitBtn">上传</button>
      </div>
    </div>
  </div>
</template>

<script>
import ColorSelector from "@/components/ColorSelector";

export default {
  name: "VideoAdd",
  components: {
    ColorSelector
  },
  data() {
    return {
      video: {
        src: Blob,
        cover: Blob,
        title: 'NoName',
        summary: 'NoSummary',
        content: 'NoContent',
        themeColor: 'grey'
      },
      coverPath: '',
    }
  },
  methods: {
    coverHandle() {
      let file = this.$refs["cover-input-er"].files[0]
      this.video.cover = file

      let objUrl;

      let that = this
      if (window.FileReader) {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = function (e) {
          objUrl = e.target.result;
          that.coverPath = objUrl;
        }
      }
    },
    setThemeColor(val) {
      this.video.themeColor = val
    },

    videoHandle() {
      this.video.src = this.$refs['videoInput'].files[0]
    },
    submit() {
      this.$refs.submitBtn.textContent = '正在上传...'
      let formData = new FormData()
      formData.append('title', this.video.title)
      formData.append('summary', this.video.summary)
      formData.append('content', this.video.content)
      formData.append('themeColor', this.video.themeColor)
      formData.append('coverFile', this.video.cover)
      formData.append('videoFile', this.video.src)
      console.log(formData.get('title'))
      console.log(formData.get('summary'))
      console.log(formData.get('content'))
      console.log(formData.get('themeColor'))
      console.log(formData.get('coverFile'))
      console.log(formData.get('videoFile'))
      // this.$refs.submitBtn.disabled = true
    }
  }
}
</script>

<style scoped lang="less">
.video-add-wrap {
  height: 1080px;
}

.text-add-wrap {
  .text-add-box {
    > div {
      margin-top: 12px;

      p {
        span {
          color: red;
        }
      }
    }
  }

  input {
    color: black;
    border: none;
    border-bottom: 2px solid white;
    outline: none;
    padding: 0 6px;
    background-color: #6e6e6e;
    box-shadow: 1px 1px 4px #3b3b3b;
  }
}

.submit {
  button {
    width: 120px;
    text-align: center;
    margin-top: 120px;
    background-color: #be1616;
  }
}
</style>