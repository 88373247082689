<template>
  <transition class="freq-slide">
    <div class="frequency-wrap">
      <div class="frequency-box">
        <div v-show="false">{{ freqHandle }}</div>
        <ul class="hvr-grow">
          <li ref="freqLi" v-for="(item,index) in frequencyData" :key="index" @click="clickFrequency(index)"
              class="dark-freq">
            <div :style="{
                'height':(item===0 ? 1/120 : item*item/120)+'px',
                }"/>
          </li>
        </ul>
      </div>
    </div>
  </transition>
</template>
<script>
import {mapState} from 'vuex'

export default {
  name: "TheFrequency",
  props: {
    getFrequencyData: {
      type: Array,
      default() {
        return []
      }
    },
    totalDuration: {
      type: Number,
      default: 0
    },
    currentDuration: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {}
  },
  created() {
  },
  methods: {
    clickFrequency(index) {
      if (this.currentDuration === 0)
        this.$emit('firstClickToPlay')

      let time = Math.round(this.totalDuration * index / this.getFrequencyData.length)
      this.$emit('changeTime', time)

      console.log(time, this.totalDuration)
      this.freqBarHandle(index)
    },

    freqBarHandle(index) {
      let length = this.frequencyData.length
      if (index >= length)
        index = length - 1

      // update light bar
      for (let i = 0; i <= index; i++)
        this.$refs.freqLi.at(i).setAttribute('class', 'light-freq')

      // update dark bar
      for (let i = index + 1; i < length; i++)
        this.$refs.freqLi.at(i).setAttribute('class', 'dark-freq')

    },
  },
  computed: {
    ...mapState(['screenType']),

    frequencyData: function () {
      let that = this
      if (this.$store.state.screenWidth < 300) {
        let width = this.$store.state.screenWidth
        let reduceSum = 100 - Math.round((width / 300) * 100)
        let reduceIndex = Math.round(this.getFrequencyData.length / reduceSum)
        let index = reduceIndex

        for (let i = 0; i < reduceSum; i++) {
          if (index > 100) {
            index = 100
            that.getFrequencyData.splice(index, 1)
            break
          }
          that.getFrequencyData.splice(index, 1)
          index = index + reduceIndex
        }

      }
      return this.getFrequencyData;
    },

    freqHandle: function () {
      // get index
      let index = Math.round((this.currentDuration / this.totalDuration) * 100)
      let length = this.frequencyData.length

      index = Math.round(length * index / 100)

      if (index >= length)
        index = length - 1

      this.freqBarHandle(index)

      return this.currentDuration;
    },

  },

}
</script>

<style scoped lang="less">
.frequency-wrap {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;

  .frequency-box {
    display: flex;
    flex-wrap: wrap;

    ul {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 12px 0;

      li {
        width: 2px;
        height: 72px;
        float: left;
        padding: 1px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        > div {
          width: 2px;
          height: 10px;
        }
      }

      li:hover {
        width: 2px;
        background-color: white;

      }

      li:hover > div {
        background-color: white;
      }
    }
  }
}

.dark-freq {
  > div {
    background-color: gray;
  }
}

.light-freq {
  > div {
    background-color: #be1616;
  }
}
</style>