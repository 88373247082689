<template>
  <div class="writing-card-wrap" :class="[index%2===1?'box-row':'box-row-reverse']">
    <div class="multi-wrap">
      <img alt="" class="picture-img hvr-grow" :src="writingData.coverSrc">
    </div>
    <div class="card-text-wrap text-bg-black">
      <div class="card-text-title">
        <h3 class="hvr-bounce-to-right">{{ writingData.title }}</h3>
      </div>
      <div class="card-text-sub">
        <p class="hvr-bounce-to-right">{{ writingData.summary }}</p>
      </div>
      <div v-show="writingData.content" class="card-text-preview">
        <p class="hvr-bounce-to-right">
          {{ writingData.content }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import '../assets/style/CommonCardStyle.less'

export default {
  name: "WritingCard",
  props: {
    index: {
      type: Number,
      default: 1
    },
    writingData: {
      id: "",
      title: "",
      summary: "",
      coverSrc: "",
      themeColor: "",
      published: new Date()
    }

  },
  data() {
    return {
    }
  }
}
</script>

<style scoped lang="less">
.picture-img {
  width: 100%;
  height: auto;
}
</style>