<template>
  <div class="color-selector">
    <div class="color-list">
      <div class="pre-cover-wrap" :style="{backgroundColor: preColor}">
        <img alt="" ref="pre-cover" class="pre-cover" :src="imgSrc">
      </div>
      <!--      <div :style="{backgroundColor: preColor}" class="pre-color">{{ preColor }}</div>-->
      <ul v-if="colorList">
        <li v-for="(item,index) in colorList" :key="index" @click="setColor(item)"
            :style="{backgroundColor: item}">
          {{ item }}
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import VueColorThief from '../utils/colorthief';

export default {
  name: "ColorSelector",
  props: {
    imgSrc: {
      type: String
    },
  },
  data() {
    return {
      colorList: [],
      preColor: ''
    }
  },
  created() {

  },
  watch: {
    imgSrc() {
      let colorThief = new VueColorThief()
      let that = this
      this.$refs['pre-cover'].addEventListener('load', function () {
        let colors = colorThief.getPalette(that.$refs['pre-cover'], 8)
        colors.forEach(function (item) {
          that.colorList.push(rgbToHex(item[0], item[1], item[2]))
        })
      })

      const rgbToHex = (r, g, b) => '#' + [r, g, b].map(x => {
        const hex = x.toString(16)
        return hex.length === 1 ? '0' + hex : hex
      }).join('')
    }
  },
  computed: {},
  methods: {
    setColor(value) {
      this.preColor = value
      this.$emit('getColor', value)
    }
  }
}
</script>

<style scoped lang="less">
.color-list {
  ul {

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;

    li {
      font-size: 14px;
      color: white;
      text-shadow: 1px 1px 1px #121212;
      width: 62px;
      height: 62px;
      line-height: 62px;
      border-radius: 50px;
      text-align: center;
      margin: 0 10px;
    }
  }
}

.pre-cover-wrap {
  text-align: center;
  height: 200px;

  .pre-cover {
    max-height: 200px;
    object-fit: cover;
  }
}

.pre-color {
  display: block;
  width: 100%;
  height: 100px;
  line-height: 100px;
  text-align: center;
  text-shadow: 1px 1px 1px #939393;
}
</style>