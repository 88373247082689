<template>
  <div class="music-card-wrap" :class="[index%2===1?'box-row':'box-row-reverse']">
    <div class="multi-wrap"
         @mouseenter="showControlHandle"
         @mouseleave="showControlHandle"
         @click="playTheMusic()">
      <div v-show="showControl" class="control-wrap">
        <div v-show="!isPlaying" class="control-play-icon"/>
        <div v-show="isPlaying" class="control-pause-icon"/>
      </div>
      <img class="picture-img hvr-grow" :src="musicData.coverSrc" alt="">
      <audio
          ref="music"
          class="auClass"
          @pause="onPause"
          @playing="onPlaying"
          :src="musicData.musicSrc"/>
    </div>
    <div class="card-text-wrap text-bg-black">
      <div class="card-text-title">
        <h3 class="hvr-bounce-to-right">{{ musicData.title }}</h3>
      </div>
      <div class="card-text-sub">
        <p class="hvr-bounce-to-right">{{ musicData.summary }}</p>
      </div>
      <div v-show="musicData.freq" class="frequency">
        <the-frequency
            :get-frequency-data="musicData.freq"
            :totalDuration="totalDuration"
            :current-duration="currentDuration"
            @changeTime="TimeHandle"
            @firstClickToPlay="playTheMusic"
        />
      </div>
    </div>
  </div>
</template>

<script>
import '../assets/style/CommonCardStyle.less'
import TheFrequency from "@/components/TheFrequency";
import {mapState} from "vuex";

export default {
  name: "MusicCard",
  components: {TheFrequency},
  props: {
    index: {
      type: Number,
      default: 1
    },
    musicData: {
      id: "",
      title: "",
      summary: "",
      musicSrc: '',
      coverSrc: "",
      themeColor: "",
      freq: [],
      published: new Date()
    }
  },
  data() {
    return {
      totalDuration: 0,
      currentDuration: 0,
      lightTheFreq: '',
      showControl: false,
      isPlaying: false,
    }
  },
  created() {
    if (this.screenType !== 1)
      this.showControl = true
  },
  methods: {
    playTheMusic() {
      let audios = document.getElementsByTagName("audio")

      if (this.$refs.music.paused) {
        for (let i = 0; i < audios.length; i++) {
          audios.item(i).pause()
        }

        this.$refs.music.play()

        if (this.totalDuration === 0)
          this.totalDuration = this.$refs.music.duration

        this.lightTheFreq = setInterval(() => {
          if (this.$refs.music.ended)
            clearInterval(this.lightTheFreq)

          if (this.totalDuration !== 0)
            this.currentDuration = this.$refs.music.currentTime

        }, 100)

      } else {
        for (let i = 0; i < audios.length; i++) {
          audios.item(i).pause()
        }
        clearInterval(this.lightTheFreq)
      }
    },
    TimeHandle(val) {
      this.$refs.music.currentTime = val
    },
    showControlHandle() {
      if (this.screenType === 1)
        this.showControl = !this.showControl
    },
    onPause() {
      this.isPlaying = false
    },
    onPlaying() {
      this.isPlaying = true
    }
  },
  computed: {
    ...mapState(['screenType'])
  }
}
</script>

<style scoped lang="less">

.picture-img {
  width: 100%;
  height: auto;
}

.av-circle-class {
  position: absolute;
  bottom: 0;
}

.control-wrap {

  .control-play-icon {
    position: absolute;
    width: 0;
    height: 0;
    z-index: 1;
    bottom: 20px;
    right: 20px;
    border-style: solid;
    border-color: transparent;
    border-width: 25px 0 25px 40px;
    border-left-color: var(--theme-white);
    pointer-events:none;
  }

  .control-pause-icon {
    position: absolute;
    width: 16px;
    height: 40px;
    z-index: 1;
    bottom: 26px;
    right: 26px;
    border-left: 12px solid var(--theme-white);
    border-right: 12px solid var(--theme-white);
    pointer-events:none;
  }
}


</style>