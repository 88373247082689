<template>
  <div class="login-wrap flexRowCenter">
    <div v-if="!isLoading" class="login-table flexRowCenter">
      <div class="title">
        <p>Login</p>
      </div>
      <div class="login-input flexRowCenter">
        <input type="text" class="email" v-model="email">
        <input type="password" class="pwd" v-model="pwd">
      </div>
      <div class="btn">
        <button @click="cancel" class="cancel" type="button">cancel</button>
        <button @click="login" class="login" type="button">login</button>
      </div>
    </div>
    <div v-show="isLoading" class="login-loading">
      <p>Loading...</p>
    </div>
  </div>
</template>

<script>
import MD5 from "js-md5"
import api from "@/api";
import router from "@/router";

export default {
  name: "TheLogin",
  data() {
    return {
      email: '',
      pwd: '',
      isLoading: false
    }
  },
  async created() {
    let rs = await api.preLogin()
    if (!rs)
      this.isLoading = false
  },
  methods: {
    cancel() {
      router.push({path: "/"})
    },
    login() {
      api.login(this.email, MD5.update(this.pwd).hex())
    }
  }

}
</script>

<style scoped lang="less">
.login-wrap {

  .login-table {
    .login-input {
      input {
        margin-top: 12px;
        color: black;
      }
    }
  }

  .btn {
    margin-top: 12px;

    button {
      width: 64px;
      color: black;
      margin: 0 12px;
    }
  }
}

</style>