<template>
  <div class="admin-wrap flexRowCenter">
    <div class="function flexRowCenter">
      <router-link to="/writingAdd" class="add-writing hvr-grow">Add-Writing</router-link>
      <router-link to="/videoAdd" class="add-video hvr-grow">Add-Video</router-link>
      <router-link to="/musicAdd" class="add-music hvr-grow">Add-Music</router-link>
      <router-link to="/postDel" class="del-post hvr-grow">Del-Post</router-link>
      <router-link to="/other" class="other hvr-grow">Other</router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: "TheAdmin",
}
</script>

<style lang="less" scoped>
.admin-wrap {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  .function {
    flex-wrap: wrap;

    a {
      margin: 32px;
      padding: 8px;
      line-height: 48px;
      font-size: 48px;
      color: white;
      background-color: black;
    }
  }
}
</style>