<template>
  <div class="main-wrap">
    <the-header/>
<!--    <div v-if="false" class="bg-video-wrap">-->
<!--      <video class="bg-video" autoplay muted loop-->
<!--             :style="{-->
<!--        'width': videoWidth,-->
<!--        'height':videoHeight,-->
<!--        'top':videoTop + 'px',-->
<!--        'left': '-' + videoLeft +'px',-->
<!--      }"-->
<!--             :src="video"></video>-->
<!--    </div>-->
    <div class="layout" :style="{'width' : layoutWidth + 'px'}">
      <router-view/>
      <div class="black-filter"/>
    </div>
    <the-footer/>
  </div>
</template>

<script>
import TheHeader from "@/components/TheHeader";
import {mapState} from 'vuex'
import TheFooter from "@/components/TheFooter";
export default {
  name: "TheMain",
  components: {
    TheHeader,
    TheFooter
  },
  data() {
    return {
      // video: require("../assets/bgv.mp4"),
      videoWidth: '100%',
      videoHeight: 'auto',
      deviceWidth: 0,
      deviceHeight: 0,
      videoLeft: '0',
      videoTop: '0',
      layoutWidth: 680,
    }
  },
  created() {
    this.deviceWidth = document.documentElement.clientWidth
    this.deviceHeight = document.documentElement.clientHeight

    this.checkScreen(this.deviceWidth, this.deviceHeight)
    this.handleVideoStyle(this.deviceWidth, this.deviceHeight)
    this.handleLayout(document.documentElement.clientWidth)


    this.$store.commit('setScreenWidth', this.deviceWidth)
  },
  methods: {
    checkScreen(deviceWidth, deviceHeight) {
      if (deviceWidth > deviceHeight) {
        if (deviceWidth / deviceHeight >= 16 / 9) {
          this.$store.commit('setScreenType', 1)
        } else {
          this.$store.commit('setScreenType', 3)
        }
      } else if (deviceWidth < deviceHeight) {
        this.$store.commit('setScreenType', 2)
      }
    },

    handleVideoStyle(deviceWidth, deviceHeight) {
      if (this.screenType === 1) {
        this.videoWidth = '100%'
        this.videoHeight = 'auto'
      } else if (this.screenType === 2 || this.screenType === 3) {
        let currentVideoWidth = deviceHeight * (18.85 / 9)
        this.videoWidth = 'auto'
        this.videoHeight = deviceHeight * 1.18 + 'px'
        this.videoLeft = (currentVideoWidth - deviceWidth) / 2
      }
    },

    handleLayout(deviceWidth) {
      if (deviceWidth < 1200) {
        this.layoutWidth = deviceWidth
      }
    },

  },
  mounted() {
    const that = this;
    window.onresize = () => {
      return (() => {
        window.screenWidth = document.documentElement.clientWidth; //实时宽度
        window.screenHeight = document.documentElement.clientHeight; //实时高度

        this.deviceWidth = this.screenWidth
        this.deviceHeight = this.screenHeight

        if (this.screenType !== 2)
          this.checkScreen(this.deviceWidth, this.deviceHeight)

        this.handleVideoStyle(this.deviceWidth, this.deviceHeight)

        this.handleLayout(this.deviceWidth)

        that.screenWidth = window.screenWidth;
        that.screenHeight = window.screenHeight;
      })();
    };
  },
  computed: {
    ...mapState(['screenType'])
  }
}
</script>

<style lang="less" scoped>
.main-wrap{
  position: relative;
}
.layout {
  margin: 0 auto;
  width: 680px;
  position: relative;
}

.bg-video-wrap {
  position: relative;
}

.bg-video {
  position: fixed;
  z-index: -1000;
}
.black-filter{
  width: 100%;
  height: 272px;
  position: fixed;
  background:linear-gradient(rgba(0,0,0,0) 0%, #000 100%);
  z-index: 1000;
  bottom: 0;
  left: 0;
  pointer-events:none;
}
</style>