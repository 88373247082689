<template>
  <div class="video-card-wrap" :class="[index%2===1?'box-row':'box-row-reverse']">
    <div class="multi-wrap">
<!--      <video ref="mv"-->
<!--             class="video-class hvr-grow"-->
<!--             @mouseenter="showControl"-->
<!--             @mouseleave="hideControl"-->
<!--             :poster="videoData.coverSrc"-->
<!--             :src="videoData.videoSrc" controlslist="nodownload"/>-->
    </div>
    <div class="card-text-wrap text-bg-black">
      <div class="card-text-title">
        <h3 class="hvr-bounce-to-right">{{ videoData.title }}</h3>
      </div>
      <div class="card-text-sub">
        <p class="hvr-bounce-to-right">{{ videoData.summary }}</p>
      </div>
      <div v-show="videoData.content" class="card-text-preview">
        <p class="hvr-bounce-to-right">
          {{ videoData.content }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import '../assets/style/CommonCardStyle.less'

export default {
  name: "VideoCard",
  props: {
    index: {
      type: Number,
      default: 1
    },
    videoData: {
      id: "",
      title: "",
      summary: "",
      content: '',
      videoSrc: '',
      coverSrc: "",
      themeColor: "",
      published: new Date()
    }
    // }
  },
  data() {
    return {
      video: {
      }
    }
  },
  methods: {
    showControl() {
      this.$refs.mv.controls = true
    },
    hideControl() {
      if (this.$refs.mv.paused === true)
        this.$refs.mv.controls = false
    }
  }

}
</script>

<style scoped lang="less">
video {
  //width: 480px;
  width: 100%;
  height: auto;
}
</style>