<template>
  <div class="music-add-wrap" :style="{'background-color':music.themeColor}">
    <div class="music-add-box">
      <div class="cover-add-wrap">
        <p>选择封面</p>
        <input ref="cover-input-er" @change="coverHandle" type="file">
        <color-selector @getColor="setThemeColor" v-show="music.cover" :imgSrc="coverPath"/>

      </div>
      <div class="frequency-analyse-box">
        <div class="analyse-audio-wrap">
          <div class="analyse-audio-box">
            <input type="file" ref="musicInput" @change="musicHandle()">
            <audio ref="music" :src="music.src"/>
            <p>分析时间间隔<span style="color: red" v-show="analyseTime===0">音频总时长：{{
                totalTime / 75 * 10
              }}</span></p>

            <input type="number" style="color: black" v-model="analyseTime">
          </div>
        </div>
        <div class="control-box">
          <button :disabled="!music.src" @click="Analyser" style="color: black">开始分析音乐频谱</button>
        </div>
        <div v-show="analyseTime!==0" class="frequency-preview-wrap">
          <div class="frequency-box">
            <ul class="mirror">
              <li ref="freqLi" v-for="(item,index) in frequencyData" :key="index"
                  :style="{
              'height':(item===0 ? 1/100 : item*item/100)+'px',
              }"></li>
            </ul>
          </div>
        </div>
      </div>
      <div class="text-add-wrap" style="width: 438px">
        <div class="text-add-box">
          <div class="title-box">
            <p>标题</p>
            <input v-model="music.title">
          </div>
          <div class="summary-box">
            <p>简介</p>
            <input v-model="music.summary">
          </div>
          <div class="content-box">
            <p>内容</p>
            <input v-model="music.content">
          </div>
        </div>
      </div>
      <div class="submit">
        <button @click="submit" ref="submitBtn">上传</button>
      </div>
    </div>
  </div>
</template>

<script>
import ColorSelector from "@/components/ColorSelector";
import api from '@/api/index'

export default {
  name: "MusicAdd",
  components: {
    ColorSelector
  },
  data() {
    return {
      frequencyData: [],
      totalDuration: 0,
      music: {
        src: Blob,
        cover: Blob,
        title: '',
        summary: '',
        content: '',
        themeColor: ''
      },
      coverPath: '',
      musicPath: "",
      analyseTime: 0,
      totalTime: 0,
      btnAble: false,
    }
  },
  methods: {
    coverHandle() {
      let file = this.$refs["cover-input-er"].files[0]
      this.music.cover = file

      let objUrl;

      let that = this
      if (window.FileReader) {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = function (e) {
          objUrl = e.target.result;
          that.coverPath = objUrl;
        }
      }
    },
    setThemeColor(val) {
      this.music.themeColor = val
    },

    musicHandle() {
      let objUrl
      let file = this.$refs['musicInput'].files[0]
      this.music.src = file

      let that = this
      if (window.FileReader) {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = function (e) {
          objUrl = e.target.result;
          that.musicPath = objUrl;
        }
      }
    },
    Analyser() {
      let audio = new Audio(this.musicPath);
      audio.play()

      this.totalTime = audio.duration

      let AudioContext = new (window.AudioContext || window.webkitAudioContext)();
      let source = AudioContext.createMediaElementSource(audio)
      let analyser = AudioContext.createAnalyser();
      source.connect(analyser);
      analyser.connect(AudioContext.destination)


      let output = new Uint8Array(1024);

      let rs = [];
      let num = 0

      this.timer = setInterval(() => {

            this.totalTime = audio.duration

            analyser.getByteFrequencyData(output);
            rs.push(output)

            let sum2 = 0
            for (let i = 0; i < 1024; i++) {
              sum2 = sum2 + rs.at(num).at(i)
            }
            this.frequencyData.push((Math.round(sum2 / 1024)))
            num++

            if (audio.ended) {
              console.log(this.frequencyData.length)
              this.frequencyData.splice(75, (this.frequencyData.length - 75) >= 1 ? (this.frequencyData.length - 75) : 0);
              console.log(this.frequencyData)
              audio.currentTime
              clearInterval(this.timer)
            }
          },
          this.analyseTime
      )
    },

    async submit() {
      this.$refs.submitBtn.textContent = '正在上传...'
      let formData = new FormData()
      formData.append('title', this.music.title)
      formData.append('summary', this.music.summary)
      formData.append('content', this.music.content)
      formData.append('themeColor', this.music.themeColor)
      formData.append('coverFile', this.music.cover)
      formData.append('musicFile', this.music.src)
      formData.append('freq', this.frequencyData + '')
      console.log(formData.get('title'))
      console.log(formData.get('summary'))
      console.log(formData.get('content'))
      console.log(formData.get('themeColor'))
      console.log(formData.get('coverFile'))
      console.log(formData.get('musicFile'))
      console.log(formData.get('freq'))
      if (confirm("是否上传？")){
        let rs = await api.addMusic(formData)
        if (rs) {
          this.$refs.submitBtn.textContent = '上传成功'
        }
      }else {
        this.$refs.submitBtn.textContent = '已取消'
      }

    }
  }
}
</script>

<style scoped lang="less">
.music-add-wrap {
  height: 1080px;

  .music-add-box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .frequency-analyse-wrap {
      .frequency-analyse-box {

        .analyse-audio-box {

        }
      }

      .text-add-wrap {
        .text-add-box {
          > div {
            margin-top: 12px;

            p {
              span {
                color: red;
              }
            }
          }
        }

        input {
          color: black;
          border: none;
          border-bottom: 2px solid white;
          outline: none;
          padding: 0 6px;
          background-color: #6e6e6e;
          box-shadow: 1px 1px 4px #3b3b3b;
        }
      }
    }
  }
}

.frequency-preview-wrap {
  height: 150px;
  width: 500px;
  position: relative;
  margin: 10px;
}

.frequency-box {
  position: absolute;
  display: flex;
  flex-wrap: wrap;
  bottom: 0;

  ul {
    li {
      float: left;
      width: 2px;
      margin-left: 2px;
      background-color: white;
    }

  }

  .mirror {
    transform: rotateX(180deg);
  }
}

.submit {
  button {
    width: 120px;
    text-align: center;
    margin-top: 120px;
    background-color: #be1616;
  }
}
</style>