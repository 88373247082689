<template>
  <div class="home-wrap">
    <div class="content">
      <div class="card-wrap">
        <ul v-show="tree">
          <li v-for="(item,index) in tree" :key="item.id">
            <WritingCard v-if="item.postType===0" :index="index+1" :writing-data="item"/>
            <VideoCard v-if="item.postType===1" :index="index+1" :video-data="item"/>
            <MusicCard v-if="item.postType===2" :index="index+1" :music-data="item"/>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import WritingCard from "@/components/WritingCard";
import MusicCard from "@/components/MusicCard";
import VideoCard from "@/components/VideoCard";
import {mapState} from "vuex";
import api from "@/api/index"

export default {
  name: "TheHome",
  components: {
    WritingCard,
    MusicCard,
    VideoCard
  },
  data() {
    return {
      tree: [],
      isEmpty: false,
      page: 0,
    }
  },
  async created() {
    this.tree = await api.getPostTree()
  },
  mounted() {
    window.addEventListener('scroll', this.lazyLoading);
  },
  methods: {
    async lazyLoading() {
      let scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
      let clientHeight = document.documentElement.clientHeight;
      let scrollHeight = document.documentElement.scrollHeight;
      if (scrollTop + clientHeight >= scrollHeight - 50 && !this.isEmpty) {
        this.page = this.page + 1
        let list = await api.getPostTree(this.page)
        if (list.length === 0)
          this.isEmpty = true
        let that = this
        list.forEach(function (item) {
          that.tree.push(item)
        })
      }
    }
  },
  computed: {
    ...mapState(['screenType'])
  }
}
</script>

<style lang="less" scoped>
.home-wrap {
  width: 100%;
  padding-top: 88px;

  .content {
    .card-wrap {
      li {
        padding-bottom: 36px;
      }
    }
  }
}
</style>