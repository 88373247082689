import {createRouter, createWebHistory} from 'vue-router'
import TheMain from "@/views/TheMain";
import TheHome from "@/views/TheHome";
import MusicAdd from "@/views/MusicAdd";
import VideoAdd from "@/views/VideoAdd";
import store from "@/store";
import TheLogin from "@/views/TheLogin";
import TheAdmin from "@/views/TheAdmin";
import PostDel from "@/views/PostDel";

const routes = [
    {
        path: '/theMain',
        component: TheMain,
        children: [
            {
                path: '/home',
                name: "home",
                component: TheHome
            },
            {
                path: "/",
                redirect: '/home'
            },
        ]
    },
    {
        path: '/musicAdd',
        name: 'musicAdd',
        component: MusicAdd
    },
    {
        path: '/videoAdd',
        name: 'videoAdd',
        component: VideoAdd
    },
    {
        path: '/login',
        name: 'login',
        component: TheLogin
    },
    {
        path: '/admin',
        name: 'admin',
        component: TheAdmin
    },
    {
        path: '/postDel',
        name: 'postDel',
        component: PostDel
    },
    {
        path: '/',
        redirect: "/theMain"
    },

]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

router.beforeEach((to, from, next) => {
    console.log(to.name)
    if (
        to.name === 'admin'
        || to.name === 'other'
        || to.name === 'postDel'
        || to.name === 'musicAdd'
        || to.name === 'videoAdd'
        || to.name === 'writingAdd'
    ) {
        store.commit("setIsLogin", localStorage.getItem('token'))
        if (!store.getters.getIsLogin && store.getters.getIsLogin !== "")
            next({path: '/home'})
        else next()
    } else next()
})

export default router
