import axios from "axios";
import router from "@/router";
import store from "@/store";

axios.defaults.baseURL = '/api';
axios.defaults.withCredentials = true

axios.interceptors.request.use(config => {
    store.commit("setIsLoading", true)
    config.headers.token = localStorage.getItem('token');
    return config
})

axios.interceptors.response.use(response => {
    store.commit("setIsLoading", false)
    if (response.data === 403 || response.data.data === 403) {
        localStorage.removeItem('token');
        router.push("/home")
    }
    return response
})
const request = {

    // 登录
    async login(email, pwd) {
        await axios.get("admin/login", {params: {email, pwd}})
            .then((rs) => {
                if (rs.data.success) {
                    localStorage.setItem('token', rs.data.data)
                    store.commit("setIsLogin", rs.data.data)
                    router.push("admin")
                }
            });
    },

    async preLogin() {
        let data = false
        await axios.get("admin/preLogin")
            .then((rs) => {
                if (rs.data.data === 1) {
                    store.commit("setIsLogin", localStorage.getItem("token"))
                    router.push("admin")
                    data = true
                }
            });
        return data;
    },

    // 获取 PostTree
    async getPostTree(page) {
        let data = Array
        await axios.get("/home/getPostTree", {params: {page}})
            .then((rs) => {
                if (rs.data.success) {
                    data = rs.data.data
                    for (let i = 0; i < data.length; i++) {
                        if (data[i].postType === 2) {
                            data[i].freq = data[i].freq.split(',')
                        }
                    }
                }
            })
        return data;
    },

    // 添加 writing
    async addWriting(formData) {
        let data = false
        await axios.post("/writing/addWriting", formData).then(r => {
            if (r.data.code === 200)
                data = true
        })
        return data
    },

    // 添加 music
    async addMusic(formData) {
        let data = false
        await axios.post("/music/addMusic", formData).then(r => {
            if (r.data.code === 200)
                data = true
        })
        return data
    },

    async getMiniPost(page) {
        let data = Array
        await axios.get("/admin/getMiniPost", {params: {page}})
            .then((rs) => {
                if (rs.data.success)
                    data = rs.data.data
            })
        return data
    },

    async delPost(formData) {
        let data = []
        await axios.post("/admin/delPost", formData).then(r => {
            if (r.data.code === 200)
                data = r.data.data
        })
        return data
    },
}
export default request